import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
} from "@angular/material/core";
import { MomentDateAdapter } from "@angular/material-moment-adapter";
import * as moment from "moment";
import { finalize } from "rxjs/operators";
import { formatDate } from "@angular/common";
import { BookingConsultantService } from "src/app/service/booking-consultant.service";
import { HomeService } from "src/app/service/home.service";
import { MatDialog } from "@angular/material/dialog";
import { GenericConfirmationComponent } from "src/app/components/dialog/generic-confirmation/generic-confirmation.component";

export const MY_FORMATS = {
  parse: {
    dateInput: "LL",
  },
  display: {
    dateInput: "YYYY-MM-DD",
    monthYearLabel: "YYYY",
    dateA11yLabel: "LL",
    monthYearA11yLabel: "YYYY",
  },
};

interface Availbility {
  year: number,
  month: number,
  day: number,
  available: boolean
}

@Component({
  selector: "app-calendar-consultant",
  templateUrl: "./calendar-consultant.component.html",
  styleUrls: ["./calendar-consultant.component.scss"],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE],
    },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
})
export class CalendarConsultantComponent implements OnInit {
  isLoading: boolean = true;
  private typeEvent: string;
  canGoBack: boolean = true;
  isComplete: boolean = false;
  private activity: string;

  private JWTUuid: string;
  private selectedDate: string;

  orari_mattino: any[] = [];
  orari_pomeriggio: any[] = [];
  orari_mattino_disabled: boolean = false;
  orari_pomeriggio_disabled: boolean = false;

  private selectedTime: boolean = false;

  minDate = new Date();
  maxDate = new Date();
  selectionDate: Date = new Date();
  private availableDays: Availbility[] = [];

  isMattinoExpanded: boolean = false;
  isPomeriggioExpanded: boolean = false;

  constructor(
    private route: Router,
    private router: ActivatedRoute,
    private bookingConsultantService: BookingConsultantService,
    private homeService: HomeService,
    private dialog: MatDialog
  ) {}

  ngOnInit() {
    this.router.params.subscribe((params) => {
      if (params) {
        this.typeEvent = params.event;
      } else {
        // Arrivo non dalla pagina precedente
        let res = this.bookingConsultantService.getConsultantReservation();
          if (res) {
            this.typeEvent = res.type;
          }
      }
    });
    let tmp = this.homeService.getUserData();
    let res = this.bookingConsultantService.getEditId();
    if (res){
      this.activity = "edit";
    }
    if (tmp[0] && tmp[1]) {
      this.JWTUuid = tmp[1];

      // Chiamare per utlimo
      this.getAvailableDays();
    } else {
      this.showError(null);
    }
  }

  private getAvailableDays() {
    this.bookingConsultantService.getConsultantsAvailableDays(this.JWTUuid, this.activity).subscribe(
      res => {
        this.availableDays = res;

        let tmpMin: Date | null = null;
        let tmpMax: Date | null = null;

        this.availableDays.forEach(el => {
          if (!tmpMin && el.available) tmpMin = new Date(el.year, el.month-1, el.day);
          if (el.available) tmpMax = new Date(el.year, el.month-1, el.day);
        });

        if (tmpMin && tmpMax) {
          this.minDate = tmpMin;
          this.maxDate = tmpMax;

          this.getMinDateSelectableMoment();
        }
      },
      error => {
        console.error(error);
        this.showError(null);
      }
    );
  }

  private getMinDateSelectableMoment() {
    let toAdd = 0;

    for(let el of this.availableDays) {
      if(el.available) break
      toAdd+=1
    }

    var obj = moment().add(toAdd, "days");
    this.handleSelection(obj);
  }

  dateFilter = (d: any): boolean => {
    var calendar_date = d._i;

    calendar_date = this.availableDays.find(el => {
      return el.year == calendar_date.year && el.month == (calendar_date.month+1) && el.day == calendar_date.date
    });

    if (calendar_date == undefined) return false;

    return calendar_date.available
  };

  handleBooking(obj: any) {
    this.orari_mattino.forEach((el) => {
      if (el.selected) {
        el.selected = false;
      }
    });

    this.orari_pomeriggio.forEach((el) => {
      if (el.selected) {
        el.selected = false;
      }
    });

    console.log('obj', obj)

    obj.selected = !obj.selected;
    this.selectedTime = obj.ora;
    this.isComplete = true;
  }

  handleSelection(event: any) {
    this.isLoading = true;

    this.isMattinoExpanded = false;
    this.isPomeriggioExpanded = false;
    this.orari_mattino_disabled = true;
    this.orari_pomeriggio_disabled = true;
    this.orari_mattino = [];
    this.orari_pomeriggio = [];

    this.selectionDate = event;
    this.selectedDate = formatDate(event._d, "yyyy-MM-dd", "en-US");
    let result = {
      date: this.selectedDate,
    };

    this.bookingConsultantService
      .getConsultantSingleDayAvailability(result, this.JWTUuid, this.activity)
      .pipe(
        finalize(() =>
          this.isDisabled(this.orari_mattino, this.orari_pomeriggio)
        )
      )
      .subscribe(
        res => {
          if (res) {
            res.forEach((el: any) => {
              if (el.time_day == 'M') {
                this.orari_mattino.push({
                  ora: el.hour,
                  stato: el.available,
                  selected: false,
                });
              } else {
                this.orari_pomeriggio.push({
                  ora: el.hour,
                  stato: el.available,
                  selected: false,
                });
              }

            });
          }

          this.isLoading = false;
        },
        err => {
          console.error(err);
          this.showError(null);
        }
      );
  }

  private isDisabled(orari_morning: any, orari_afternoon: any) {
    if (orari_morning.length == 0) {
      this.orari_mattino_disabled = true;
    } else {
      this.isMattinoExpanded = true;
      this.orari_mattino_disabled = false;
    }

    if (orari_afternoon.length == 0) {
      this.orari_pomeriggio_disabled = true;
    } else {
      this.orari_pomeriggio_disabled = false;
    }
  }

  goTo(path: string) {
    switch(path) {
      case 'summary':
        let result = {
          type: this.typeEvent,
          data: this.selectedDate,
          ora: this.selectedTime,
        };

        this.bookingConsultantService.sendConsultantReservation(result);
        this.route.navigate(["summary-consultant"]);
        break;
      case 'home':
        this.route.navigate(["/home-booked-consultant"]);
        break;
    }
  }

  private showError(err: any) {
    const dialogRef = this.dialog.open(GenericConfirmationComponent, {
      data: {
        message: "Non è possibile prenotare l'appuntamento con il tuo consulente. Contatta la tua filiale di riferimento per fissare un nuovo appuntamento."
      }
    });

    dialogRef.afterClosed().subscribe((result) => {
      this.goTo('home');
    });
  }

}
