<div *ngIf="isLoading">
  <div class="intestazione-container">
    <img class="image-footer" src="../../../assets/img/Logo-Credem-Wellbanking_rgb_alta.jpg">
  </div>
  <div class="spinner-loading">
    <div>
      <span class="spinner-double-dot-stick"></span>
    </div>
  </div>
</div>

<div *ngIf="!isLoading" class="complete">
  <div class="intestazione-container">
    <img
      class="image-footer"
      src="../../../assets/img/Logo-Credem-Wellbanking_rgb_alta.jpg"
    />
  </div>
  <div class="progress-container">
    <mat-progress-bar mode="determinate" value="100"></mat-progress-bar>
  </div>
  <div class="title-container">
    <h3 style="font-weight: bold; color: green">Esito Prenotazione</h3>
  </div>
  <div class="icon-container">
    <img class="image-success" src="{{ successImage }}" />
  </div>
  <div class="subtitle-container">
    <span
      class="title-class"
      [ngStyle]="{ color: isSuccess ? '#20623b' : 'red' }"
      >{{ successTitle }}</span
    >
  </div>

  <div *ngIf="!isSuccess" class="sub-title-class">
    <p>
      {{ errorMessage }}
    </p>
  </div>

  <div *ngIf="isSuccess && !isConsultant" class="body-riepilogo">
    <mat-list>
      <mat-list-item style="color: grey">Filiale</mat-list-item><br />
      <mat-list-item>{{ filiale }} - {{ address }}</mat-list-item>
      <mat-divider></mat-divider>
      <mat-list-item style="color: grey">Data e Ora</mat-list-item><br />
      <mat-list-item>{{ data | date: "d-MM-yy" }} - {{ orario }}</mat-list-item>
      <mat-divider></mat-divider>
      <mat-list-item style="color: grey">Nome</mat-list-item><br />
      <mat-list-item>{{ nome }}</mat-list-item>
      <mat-divider></mat-divider>
      <div *ngIf="surname !== null && surname !== '' && surname !== undefined">
      <mat-list-item style="color: grey">Cognome</mat-list-item><br />
      <mat-list-item>{{ surname }}</mat-list-item>
      <mat-divider></mat-divider>
      </div>
      <mat-list-item style="color: grey">Email</mat-list-item><br />
      <mat-list-item>{{ email }}</mat-list-item>
      <mat-divider></mat-divider>
    </mat-list>
  </div>

  <div *ngIf="isSuccess && isConsultant" class="body-riepilogo">
    <mat-list>
      <mat-list-item style="color: grey">Il tuo consulente</mat-list-item><br />
      <mat-list-item
        >{{ consultantName }}</mat-list-item
      >
      <mat-divider></mat-divider>
      <mat-list-item style="color: grey">Data e Ora</mat-list-item><br />
      <mat-list-item>{{ data | date: "d-MM-yy" }} - {{ orario }}</mat-list-item>
      <mat-divider></mat-divider>
      <mat-list-item style="color: grey">Nome</mat-list-item><br />
      <mat-list-item>{{ nome }}</mat-list-item>
      <mat-divider></mat-divider>

      <div *ngIf="surname !== null && surname !== '' && surname !== undefined">
      <mat-list-item style="color: grey">Cognome</mat-list-item><br />
      <mat-list-item>{{ surname }}</mat-list-item>
      <mat-divider></mat-divider>
      </div>

      <mat-list-item style="color: grey">Email</mat-list-item><br />
      <mat-list-item>{{ email }}</mat-list-item>
      <mat-divider></mat-divider>
      <mat-list-item style="color: grey">Cellulare</mat-list-item><br />
      <mat-list-item>{{ phone }}</mat-list-item>
      <mat-divider></mat-divider>
      <mat-list-item style="color: grey">Argomento</mat-list-item><br />
      <mat-list-item>{{ argument }}</mat-list-item>
      <mat-divider></mat-divider>
    </mat-list>
  </div>

  <div *ngIf="!isSuccess" class="btn-container">
    <button class="btn-submit" (click)="goToCalendar(isConsultant)" style="margin-bottom: 20px;">
      Ritenta
    </button>
  </div>

  <div *ngIf="isSuccess && !isConsultant" class="btn-container">
    <button class="btn-submit" (click)="goToHome()" style="margin-bottom: 20px;">
      Nuovo Appuntamento
    </button>
  </div>

  <div *ngIf="isSuccess && isConsultant && isCallCenter" class="btn-container">
    <button class="btn-submit" (click)="goToHomeCallCenter()" style="margin-bottom: 20px;">
      Nuovo Appuntamento
    </button>
  </div>

</div>
