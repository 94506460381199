<div *ngIf="isLoading">
  <div class="intestazione-container">
    <img class="image-footer" src="../../../assets/img/Logo-Credem-Wellbanking_rgb_alta.jpg">
  </div>
  <div class="spinner-loading">
    <div>
      <span class="spinner-double-dot-stick"></span>


    </div>
  </div>
</div>

<div *ngIf="!isLoading">
  <div class="intestazione-container">
    <div class="back-btn-container">
      <button class="btn-summary d-none d-md-block" (click)="goBack()">
        <mat-icon>chevron_left</mat-icon>
      </button>
      <p class="back-written d-none d-md-block" (click)="goBack()">indietro</p>
      <button class="btn-mobile d-md-none" (click)="goBack()">
        <mat-icon>chevron_left</mat-icon>
      </button>
    </div>
    <img class="image-footer" src="../../../assets/img/Logo-Credem-Wellbanking_rgb_alta.jpg" />
  </div>
  <div class="progress-container">
    <mat-progress-bar mode="determinate" value="75"></mat-progress-bar>
  </div>
  <div class="title-container">
    <h2>Riepilogo Prenotazione</h2>
  </div>

  <div class="row">
    <form [formGroup]="formConsultant">
      <div class="summary-container">
        <mat-list>

          <ng-container *ngIf="!jwt.clienteRemoto">
            <mat-list-item>Il tuo consulente</mat-list-item><br>
            <mat-list-item class="direct">{{ consultantName }}</mat-list-item>
            <mat-divider></mat-divider>
          </ng-container>

          <mat-list-item>Data e Ora</mat-list-item><br>
          <mat-list-item class="direct">{{ data | date: "d-MM-yy" }} - {{ time }}
          </mat-list-item>

          <div *ngIf="!clienteIntestazione">
            <mat-divider></mat-divider>

            <mat-list-item>Nome</mat-list-item><br>
            <input type="text" class="form-control" formControlName="name" maxlength="150"/>
            <mat-error *ngIf="
              formConsultant.get('name')?.hasError('required') &&
              formConsultant.get('name')?.touched
            ">{{ "Il nome è obbligatorio" }}</mat-error>
            <mat-divider></mat-divider>

            <mat-list-item>Cognome</mat-list-item><br>
            <input type="text" class="form-control" formControlName="surname" maxlength="150"/>
            <mat-error *ngIf="
              formConsultant.get('surname')?.hasError('required') &&
              formConsultant.get('surname')?.touched
            ">{{ "Il cognome è obbligatorio" }}</mat-error>
          </div>

          <div *ngIf="clienteIntestazione">
            <mat-divider></mat-divider>
            <mat-list-item>Intestazione</mat-list-item><br>
            <input class="form-control" type="text" formControlName="intestazione" />
          </div>
          <mat-divider></mat-divider>

          <mat-list-item>Email</mat-list-item><br>
          <input class="form-control" type="email" formControlName="email" maxlength="150"/>
          <mat-error
            *ngIf="formConsultant.get('email')?.hasError('required') &&
              formConsultant.get('email')?.touched">
            {{"L'email è obbligatoria"}}</mat-error>
          <mat-error *ngIf="formConsultant.get('email')?.hasError('email')">
            {{"L'email non è valida"}}</mat-error>
          <mat-divider></mat-divider>

          <ng-container *ngIf="!jwt">
            <mat-list-item>Ripeti Email</mat-list-item>
            <input class="form-control" type="email" formControlName="email_2" onpaste="return false" ondrop="return false"/>
            <mat-error
              *ngIf="formConsultant.get('email_2')?.touched && formConsultant.get('email')?.touched
                && checkEmailFields()">
              {{"Le due email non coincidono"}}
            </mat-error>
            <mat-divider></mat-divider>
          </ng-container>

          <mat-list-item>Cellulare</mat-list-item><br>
          <input type="text" class="form-control" formControlName="phoneNumber" maxlength="10"/>
          <mat-error *ngIf="
              formConsultant.get('phoneNumber')?.hasError('required') &&
              formConsultant.get('phoneNumber')?.touched
            ">{{ "Il numero di telefono è obbligatorio" }}</mat-error>
          <mat-error *ngIf="
            formConsultant.get('phoneNumber')?.touched
            && checkPhoneField()
          ">{{ "Il numero di telefono non è corretto" }}</mat-error>
          <mat-divider></mat-divider>

          <div class="recap">
            <h3 style="font-weight: bold">
              Per confermare la prenotazione seleziona l'argomento che desideri
              trattare con il tuo consulente.
            </h3>
          </div>

          <mat-list-item>Argomento</mat-list-item><br />
          <mat-form-field class="col-md-6 col-12" appearance="legacy">
            <mat-select formControlName="selectArgument">
              <mat-option *ngFor="let item of selectArgument" [value]="item.viewValue">{{ item.viewValue }}
              </mat-option>
            </mat-select>
          </mat-form-field>

        </mat-list>
      </div>

      <div class="p-4" *ngIf="formConsultant.value.selectArgument == 'Altro'">
        <textarea type="text" formControlName="argument"
          placeholder="Specifica brevemente l'argomento che vorresti trattare con il consulente."></textarea>
      </div>

      <div class="term-conditions" *ngIf="!jwt">
        <mat-checkbox class="termini" formControlName="terminiChecked">Dichiaro di aver letto e compreso l'<a
            href="https://www.credem.it/content/dam/credem/documenti/informative_privacy/3_Info_prenota_appuntamento_filiale_2021.pdf"
            target="_blank">informativa privacy</a>
        </mat-checkbox>
      </div>

      <div class="captcha-container" *ngIf="!jwt">
        <re-captcha (resolved)="resolved()" (error)="onError()" errorMode="handled"
          siteKey="6Ld4hp0bAAAAAMGQupMNigbgoWK0xOyJIZfQhj15">
        </re-captcha>
      </div>

      <div class="submit-btn">
        <button [ngClass]="
            formConsultant.valid && !checkFormInvalid() ? 'btn-submit' : 'btn-disabled'
          " [disabled]="formConsultant.invalid || checkFormInvalid()" (click)="onSubmit()">
          Conferma Appuntamento
        </button>
      </div>

    </form>
  </div>
</div>
