<div *ngIf="isLoading">
  <div class="intestazione-container">
    <img class="image-footer" src="../../../assets/img/Logo-Credem-Wellbanking_rgb_alta.jpg">
  </div>
  <div class="spinner-loading">
    <div>
      <span class="spinner-double-dot-stick"></span>


    </div>
  </div>
</div>

<div *ngIf="!isLoading" class="home-landing-booked">
  <div class="intestazione-container">
    <img
      class="image-footer"
      src="../../../assets/img/Logo-Credem-Wellbanking_rgb_alta.jpg"
    />
  </div>
  <div class="progress-container">
    <mat-progress-bar mode="determinate" value="25"></mat-progress-bar>
  </div>
  <div class="p-5 row w-100">
    <div class="half-page-title">
      <h2 style="color: green; display: flex; justify-content: center">
        Scegli la modalità:
      </h2>
    </div>

    <mat-list>
      <mat-list-item style="color: grey">Il tuo Consulente</mat-list-item><br />
      <mat-list-item>{{ nameConsultant }}</mat-list-item>
      <mat-divider></mat-divider>
    </mat-list>

  </div>
  <div class="p-5">
    <div class="half-page-title">
      <h2 style="color: green">Scegli la modalità:</h2>
    </div>
    <mat-radio-group aria-label="Select an option" [(ngModel)]="radiobtn">
      
      <mat-divider></mat-divider>

      <div *ngIf="hasMeet" class="d-flex p-2 align-middle justify-content-between">
        <h3 class="mb-0" style="margin-right: 1em">Online - Google Meet</h3>
        <mat-radio-button
          value="Meet"
        ></mat-radio-button>
      </div>

      <mat-divider *ngIf="hasFiliale"></mat-divider>

      <div *ngIf="hasFiliale" class="d-flex p-2 align-middle justify-content-between">
        <h3 class="mb-0" style="margin-right: 1em">In Filiale</h3>
        <mat-radio-button
          value="Filiale"
        ></mat-radio-button>
      </div>
      
      <mat-divider></mat-divider>

      <div *ngIf="hasTelefono" class="d-flex p-2 align-middle justify-content-between">
        <h3 class="mb-0" style="margin-right: 1em">Contatto Telefonico</h3>
        <mat-radio-button
          value="Telefono"
        ></mat-radio-button>
      </div>
      
      <mat-divider></mat-divider>

    </mat-radio-group>
  </div>

  <div class="btn-container">
    <button
      [ngClass]="radiobtn ? 'btn-submit' : 'btn-disabled'"
      (click)="goToCalendar()"
      [disabled]="!radiobtn"
      style="margin-bottom: 20px;"
    >
      Procedi
    </button>
  </div>
</div>
