import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { StorageService } from './storage.service';

@Injectable({
  providedIn: 'root',
})
export class HomeService {

  private userData: any;
  private uuid: string;

  private errorMessage: string | undefined = undefined;

  constructor(private http: HttpClient, private storage: StorageService) {}

  getJWT(id: string): Observable<any> {
    let url = environment.backEndUrl + `/reservations/redirect-url`;
    let params = new HttpParams();
    params = params.append('id', id);
    
    return this.http.get(url, {params: params});
  }

  setUserData(userData: any, id: string) {
    this.userData = userData;
    this.uuid = id;
    this.storage.addElement([this.userData, this.uuid], 'userData');
  }

  getUserData(): any[] {
    let data = this.storage.getElement('userData');
    if(data) {
      this.userData = data[0];
      this.uuid = data[1];
    }

    return [this.userData, this.uuid];
  }

  setErrorMessage(message: string) {
    this.errorMessage = message;
  }

  getErrorMessage() {
    let tmp = this.errorMessage;
    this.errorMessage = undefined;
    return tmp;
  }

  getBlacklist(): Observable<any> {
    let url = environment.backEndUrl + '/settings/blacklist';
    return this.http.get(url);
  }

}
