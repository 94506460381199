<div *ngIf="isLoading">
  <div class="intestazione-container">
    <img class="image-footer" src="../../../assets/img/Logo-Credem-Wellbanking_rgb_alta.jpg">
  </div>
  <div class="spinner-loading">
    <div>
      <span class="spinner-double-dot-stick"></span>


    </div>
  </div>
</div>

<div *ngIf="!isLoading" class="calendar-consultant">
  <div class="body-top-container">
    <div class="intestazione-container">
      <div *ngIf="canGoBack" class="back-btn-container">
        <button
          class="btn-summary d-none d-md-block"
          (click)="goTo('home')"
        >
          <mat-icon>chevron_left</mat-icon>
        </button>
        <p
          class="back-written d-none d-md-block"
          (click)="goTo('home')"
        >
          indietro
        </p>
        <button class="btn-mobile d-md-none" (click)="goTo('home')">
          <mat-icon>chevron_left</mat-icon>
        </button>
      </div>
      <img
        class="image-footer"
        src="../../../assets/img/Logo-Credem-Wellbanking_rgb_alta.jpg"
      />
    </div>
    <div class="progress-container">
      <mat-progress-bar mode="determinate" value="50"></mat-progress-bar>
    </div>
    <div class="title-container">
      <h2>Scegli data ed ora appuntamento</h2>
    </div>

    <div class="row">
      <div class="col-md-4 col-12">
        <div class="calendar-container">
          <mat-calendar
            [startAt]="selectionDate"
            [selected]="selectionDate"
            [minDate]="minDate"
            [maxDate]="maxDate"
            [dateFilter]="dateFilter"
            (selectedChange)="handleSelection($event)"
          ></mat-calendar>
        </div>
      </div>
      <div class="col-md-8 col-12">
        <div class="time-container">
          <div style="margin-top: 2em">
            <mat-accordion>

              <mat-expansion-panel [disabled]="orari_mattino_disabled" [expanded]="isMattinoExpanded">
                <mat-expansion-panel-header>
                  <mat-panel-title> Mattina </mat-panel-title>
                </mat-expansion-panel-header>
                <button class="time-button" *ngFor="let morning of orari_mattino" (click)="handleBooking(morning)"
                  [ngClass]="[!morning.stato ? 'time-btn-disabled' : '', morning.selected ? 'isSelected' : '']"
                  [disabled]="!morning.stato"
                >
                  {{ morning.ora }}
                </button>
              </mat-expansion-panel>

              <mat-expansion-panel [disabled]="orari_pomeriggio_disabled" [expanded]="isPomeriggioExpanded">
                <mat-expansion-panel-header>
                  <mat-panel-title> Pomeriggio </mat-panel-title>
                </mat-expansion-panel-header>
                <button class="time-button" *ngFor="let afternoon of orari_pomeriggio"  (click)="handleBooking(afternoon)"
                  [ngClass]="[!afternoon.stato? 'time-btn-disabled' : '', afternoon.selected? 'isSelected': '']"
                  [disabled]="!afternoon.stato">{{afternoon.ora}}</button>
              </mat-expansion-panel>

            </mat-accordion>
          </div>
        </div>
      </div>
    </div>
    <div class="submit-btn">
      <button
        [ngClass]="isComplete ? 'btn-submit' : 'btn-disabled'"
        (click)="goTo('summary')"
        [disabled]="!isComplete"
        style="margin-bottom: 20px;"
      >
        Procedi
      </button>
    </div>
    <br />
    <br />
  </div>
</div>
