import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { BookingConsultantService } from "src/app/service/booking-consultant.service";
import { finalize } from "rxjs/operators";
import { HomeService } from "src/app/service/home.service";
import { MatDialog } from "@angular/material/dialog";
import { GenericConfirmationComponent } from "src/app/components/dialog/generic-confirmation/generic-confirmation.component";

@Component({
  selector: "app-summary-booked",
  templateUrl: "./summary-booked.component.html",
  styleUrls: ["./summary-booked.component.scss"],
})
export class SummaryBookedComponent implements OnInit {
  isLoading: boolean = true;
  time: string;
  data: string;
  private idReservation: string;
  private new_idReservation: string;

  private token: string;
  private tokenSolved: boolean = false;
  private type: string;

  consultantName: string;
  clienteIntestazione: boolean = false;

  blacklist: Array<string>;

  jwt: any = '';
  private uuid: string;



  formConsultant: FormGroup = this.fb.group({
    name: [""],
    surname: [""],
    email: ["", [Validators.email, Validators.required]],
    email_2: ["", [Validators.email, Validators.required]],
    phoneNumber: ["", Validators.required],
    selectArgument: ["", Validators.required],
    terminiChecked: [false],
    argument: [""],
    intestazione: [""]
  });

  selectArgument = [
    { value: 0, viewValue: "Finanziamenti e Affidamenti" },
    { value: 1, viewValue: "Investimenti" },
    { value: 2, viewValue: "Assicurazioni" },
    { value: 3, viewValue: "Carte e Pagamenti" },
    { value: 4, viewValue: "Altro" },
  ];

  constructor(
    private route: Router,
    private bookingConsultantService: BookingConsultantService,
    private fb: FormBuilder,
    private homeService: HomeService,
    private dialog: MatDialog
  ) {}

  ngOnInit() {

    let res3 = this.bookingConsultantService.getConsultantReservation();
    if (res3) {
      this.time = res3.ora;
      this.data = res3.data;
      this.type = res3.type;
    }

    this.formConsultant
      .get("selectArgument")
      ?.valueChanges.subscribe((value) => {
        if (value === "Altro") {
          this.formConsultant
            .get("argument")
            ?.setValidators(Validators.required);
          this.formConsultant.get("argument")?.updateValueAndValidity();
          
          if(this.jwt.argomento) this.formConsultant.get("argument")?.setValue(this.jwt.argomento);
        } else {
          this.formConsultant.get("argument")?.clearValidators();
          this.formConsultant.get("argument")?.updateValueAndValidity();
        }
      }
    );

    this.getJwt();

    if (!this.jwt) {
      this.homeService.getBlacklist().subscribe(
        res => {
          this.blacklist = res;
          this.isLoading = false;
        }
      );
    } else {
      this.isLoading = false;
    }
  }

  private getJwt() {
    let tmp = this.homeService.getUserData();
    if (tmp[0] && tmp[1]) {

      this.jwt = tmp[0].jwt;
      this.uuid = tmp[1];

      this.consultantName = this.jwt.gestoreDescrizione;
      if (this.jwt.clienteRemoto){
        if (this.jwt.clienteRemoto == "false"){
          this.jwt.clienteRemoto = false;
        }
        else{
          this.jwt.clienteRemoto = true;
        }
      }

      if (this.jwt.clienteIntestazione !== undefined) {
        this.clienteIntestazione = true;
        this.formConsultant.get("intestazione")?.setValue(this.jwt.clienteIntestazione);
      } else {
        this.formConsultant.get("name")?.setValue(this.jwt.clienteNome);
        this.formConsultant.get("surname")?.setValue(this.jwt.clienteCognome);
      }

      if (this.jwt.argomento){
        this.formConsultant.get("selectArgument")?.setValue("Altro");
      } else if (this.jwt.dropdown_argument) {
        let old_argument: string | undefined = this.selectArgument.find(el => el.viewValue == this.jwt.dropdown_argument)?.viewValue;
        if(old_argument) this.formConsultant.get("selectArgument")?.setValue(old_argument);
      }

      this.formConsultant.get("email")?.setValue(this.jwt.clienteEmail);
      this.formConsultant.get("phoneNumber")?.setValue(this.jwt.clienteCellulare);

      this.formConsultant.disable();

      this.formConsultant.get("terminiChecked")?.enable();
      this.formConsultant.get("selectArgument")?.enable();
      this.formConsultant.get("argument")?.enable();

      // Per ora il numero di telefono è l'unico campo opzionale in caso di ricezione di JWT
      if (!this.jwt.clienteCellulare) {this.formConsultant.get("phoneNumber")?.enable();}
    } else {
      //
    }

  }

  goBack() {
    this.route.navigate(["/date-picking-consultant", { event: this.type }]);
  }

  onSubmit() {
    if (!this.jwt) {
      let valid = this.checkBlacklist();
      if (!valid) return;
    }

    this.isLoading = true;
    let result = {
      date: this.data,
      hour: this.time,
      user_name: this.formConsultant.get("name")?.value,
      user_surname: this.formConsultant.get("surname")?.value,
      user_email: this.formConsultant.get("email")?.value,
      user_phone: this.formConsultant.get("phoneNumber")?.value,
      user_argument: this.formConsultant.get("selectArgument")?.value,
      argument: this.formConsultant.get("argument")?.value,
      meeting_type: this.type,
      id_consultant: undefined,
      token: this.token,
      clienteCdg: undefined,
      intestazione: undefined,
    }

    if (this.jwt) {
      result.user_name = this.jwt.clienteNome;
      result.user_surname = this.jwt.clienteCognome;
      result.user_email = this.jwt.clienteEmail;
      if(this.jwt.clienteCellulare) result.user_phone = this.jwt.clienteCellulare;
      result.clienteCdg = this.jwt.clienteCdg;
      if(this.jwt.clienteIntestazione !== undefined)
      {
        result.intestazione = this.jwt.clienteIntestazione;
      }
    }

    let reservationService = undefined;

    let res = this.bookingConsultantService.getEditId();
    if (res){
      this.idReservation = res;
    }

    if (this.idReservation){
      reservationService = this.bookingConsultantService.editReservationConsultant(result, this.idReservation);
    }else {
      reservationService = this.bookingConsultantService.createReservationConsultant(result, this.uuid);
    }


    reservationService
      .pipe(finalize(() => this.goToComplete(this.new_idReservation)))
      .subscribe(
        res => {
          if (res) {
            this.new_idReservation = res.id_consultant_reservation;
          }
        },
        err => {
          if (err.error.type == "handled") { this.homeService.setErrorMessage(err.error.message); }
        }
      );
  }

  private goToComplete(idReservation: string) {
    this.route.navigate(["complete", { idReservation }]);
  }

  resolved() {
    this.token = grecaptcha.getResponse();
    this.tokenSolved = true;
  }

  onError() {
    console.log(`reCAPTCHA error encountered; details:`);
  }

  // Return True if invalid
  checkEmailFields() {
    return (!this.formConsultant.get('email_2')?.hasError('email') && this.formConsultant.get("email")?.value != this.formConsultant.get("email_2")?.value);
  }

  // Return True if invalid
  checkPhoneField() {
    const numberformat = /^[\d]{9,10}$/;

    let phone: string = this.formConsultant.get('phoneNumber')?.value;

    return phone.length != 0 && !phone.match(numberformat);
  }

  checkFormInvalid(){
    if(!this.formConsultant.get("intestazione")?.value && !(this.formConsultant.get("name")?.value && this.formConsultant.get("surname")?.value)){
      return true;
    }

    if (this.jwt) {
      return false;
    } else {
      if(this.checkEmailFields()) return true;
      if(this.checkPhoneField()) return true;

      return (!this.formConsultant.get("terminiChecked")?.value || !this.tokenSolved);
    }
  }

  private checkBlacklist(): boolean {
    let email: string = this.formConsultant.get("email")?.value;

    let match = this.blacklist.find(el => email.includes(el));

    if (!match) return true;

    const dialogRef = this.dialog.open(GenericConfirmationComponent, {
      data: {
        message: "Non è possibile fissare un appuntamento con l'indirizzo e-mail indicato. L'indirizzo e-mail non deve contenere '" + match + "'."
      }
    });

    /*dialogRef.afterClosed().subscribe((result) => {
      //
    });*/

    this.formConsultant.get("email")?.setValue('');
    this.formConsultant.get("email_2")?.setValue('');

    return false;
  }
}
