import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { HomeService } from 'src/app/service/home.service';

@Component({
  selector: 'app-home-landing-booked',
  templateUrl: './home-landing-booked.component.html',
  styleUrls: ['./home-landing-booked.component.scss'],
})
export class HomeLandingBookedComponent implements OnInit {
  nameConsultant: string;
  surnameConsultant: string;
  radiobtn: string;
  isLoading: boolean;
  subscription: any;

  hasMeet: boolean = false;
  hasFiliale: boolean = false;
  hasTelefono: boolean = false;

  constructor(
    private route: Router,
    private router: ActivatedRoute,
    private homeService: HomeService,
    public dialog: MatDialog
  ) { }

  ngOnInit() {
    this.isLoading = true;
    this.subscription = this.router.params.subscribe((params) => {

    });
    this.getJWT();
  }

  private getJWT() {
    let tmpData = this.homeService.getUserData();

    if (tmpData[0]) {

      if(((tmpData[0].jwt) && (tmpData[0].jwt.clienteRemoto)) || (tmpData[0].clienteRemoto)) {
        this.radiobtn = "Meet";
        this.goToCalendar();
      } else {
        this.nameConsultant = tmpData[0].jwt.gestoreDescrizione;
        var tmp = tmpData[0].jwt.opzioniPrenotazioneConsulente.split(",");

        this.hasMeet = tmp.includes("Meet");
        this.hasFiliale = tmp.includes("Filiale")
        this.hasTelefono = tmp.includes("Telefono")

        if (this.hasMeet && !this.hasFiliale && !this.hasTelefono) this.radiobtn = "Meet";
        if (this.hasFiliale && !this.hasMeet && !this.hasTelefono) this.radiobtn = "Filiale";

        this.isLoading = false;
      }

    }
  }

  goToCalendar() {
    this.route.navigate(['/date-picking-consultant', { event: this.radiobtn}]);
  }

}
