<div *ngIf="isLoading">
  <div class="intestazione-container">
    <img class="image-footer" src="../../../assets/img/Logo-Credem-Wellbanking_rgb_alta.jpg">
  </div>
  <div class="spinner-loading">
    <div>
      <span class="spinner-double-dot-stick"></span>


    </div>
  </div>
</div>

<div *ngIf="!isLoading">
  <div class="intestazione-container">
    <div class="back-btn-container">
      <button class="btn-summary d-none d-md-block" (click)="goBack()">
        <mat-icon>chevron_left</mat-icon>
      </button>
      <p class="back-written d-none d-md-block" (click)="goBack()">indietro</p>
      <button class="btn-mobile d-md-none" (click)="goBack()">
        <mat-icon>chevron_left</mat-icon>
      </button>
    </div>
    <img class="image-footer" src="../../../assets/img/Logo-Credem-Wellbanking_rgb_alta.jpg" />
  </div>
  <div class="progress-container">
    <mat-progress-bar mode="determinate" value="75"></mat-progress-bar>
  </div>
  <div class="title-container">
    <h2>Riepilogo Prenotazione</h2>
  </div>
  
  <div class="row">
    <div class="recap">
      <p *ngIf="!jwt">
        Per poter confermare la tua prenotazione devi compilare i seguenti campi:
      </p>
      <p *ngIf="jwt">
        Prima di confermare la tua prenotazione, verifica la correttezza dei seguenti campi:
      </p>
    </div>
  </div>

  <div class="row">
    <form [formGroup]="form">
      <div class="summary-container">
        <mat-list>

          <mat-list-item>Filiale</mat-list-item><br>
          <mat-list-item class="direct">{{ filiale }} - {{ address }}</mat-list-item>
          <mat-divider></mat-divider>

          <mat-list-item>Data e Ora</mat-list-item><br>
          <mat-list-item class="direct">{{ data | date: "d-MM-yy" }} - {{ time }}
          </mat-list-item>

          <div *ngIf="!clienteIntestazione">
            <mat-divider></mat-divider>
            
            <mat-list-item>Nome</mat-list-item>
            <input type="text" class="form-control" formControlName="name" maxlength="150"/>
            <mat-error
              *ngIf="form.get('name')?.hasError('required') &&
              form.get('name')?.touched">
              {{ "Il nome è obbligatorio" }}
            </mat-error>
            <mat-error
              *ngIf="form.get('name')?.hasError('forbiddenName') &&
              form.get('name')?.touched">
              {{ "Il nome non è valido" }}
            </mat-error>
            <mat-divider></mat-divider>
            
            <mat-list-item>Cognome</mat-list-item>
            <input type="text" class="form-control" formControlName="surname" maxlength="150"/>
            <mat-error
              *ngIf="form.get('surname')?.hasError('required') &&
              form.get('surname')?.touched">
              {{ "Il cognome è obbligatorio" }}
            </mat-error>
            <mat-error
              *ngIf="form.get('surname')?.hasError('forbiddenName') &&
              form.get('surname')?.touched">
              {{ "Il cognome non è valido" }}
            </mat-error>
          </div>

          <div *ngIf="clienteIntestazione">
            <mat-divider></mat-divider>
            <mat-list-item>Intestazione</mat-list-item>
            <input class="form-control" type="text" formControlName="intestazione" />
          </div>
          
          <mat-divider></mat-divider>

          <mat-list-item>Email</mat-list-item>
          <input class="form-control" type="email" formControlName="email" maxlength="150"/>
          <mat-error *ngIf="
              form.get('email')?.hasError('required') &&
              form.get('email')?.touched
            ">{{ "L'email è obbligatoria" }}</mat-error>
          <mat-error *ngIf="form.get('email')?.hasError('email')">
            {{"L'email non è valida"}}</mat-error>
          <mat-divider></mat-divider>

          <ng-container *ngIf="!jwt">
            <mat-list-item>Ripeti Email</mat-list-item>
            <input class="form-control" type="email" formControlName="email_2" onpaste="return false" ondrop="return false"/>
            <mat-error
              *ngIf="form.get('email_2')?.touched && form.get('email')?.touched
                && checkEmailFields()">
              {{"Le due email non coincidono"}}
            </mat-error>
            <mat-error *ngIf="form.get('email_2')?.hasError('email')">
              {{"L'email non è valida"}}</mat-error>
            <mat-divider></mat-divider>
          </ng-container>
        </mat-list>
      </div>


      <div class="term-conditions" *ngIf="!jwt">
        <mat-checkbox class="termini" formControlName="terminiChecked">Dichiaro di aver letto e compreso l'<a
            href="https://www.credem.it/content/dam/credem/documenti/informative_privacy/3_Info_prenota_appuntamento_filiale_2021.pdf"
            target="_blank">informativa privacy</a>
        </mat-checkbox>
      </div>
      <div class="captcha-container" *ngIf="!jwt">
        <re-captcha (resolved)="resolved()" (error)="onError()" errorMode="handled"
          siteKey="6Ld4hp0bAAAAAMGQupMNigbgoWK0xOyJIZfQhj15">
        </re-captcha>
      </div>

      <div class="submit-btn">
        <button [ngClass]="form.valid && !checkFormInvalid() ? 'btn-submit' : 'btn-disabled'" [disabled]="form.invalid || checkFormInvalid()"
          (click)="onSubmit()">
          Conferma Appuntamento
        </button>
      </div>

    </form>
  </div>
</div>