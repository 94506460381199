import { BrowserModule } from "@angular/platform-browser";
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from "@angular/core";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { HomeLandingComponent } from "./pages/home/home-landing-cassa/home-landing.component";
import { HeaderComponent } from "./components/header/header.component";
import { FooterComponent } from "./components/footer/footer.component";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { HttpClientModule } from "@angular/common/http";
import { MatSelectModule } from "@angular/material/select";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatNativeDateModule, MAT_DATE_LOCALE } from "@angular/material/core";
import { SummaryComponent } from "./pages/summary/summary-cassa/summary.component";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { CompleteBookingComponent } from "./pages/complete-booking/complete-booking.component";
import { RecaptchaFormsModule, RecaptchaModule } from "ng-recaptcha";
import { CalendarComponent } from "./pages/calendar/calendar-cassa/calendar.component";
import { MatProgressBarModule } from "@angular/material/progress-bar";
import { MatExpansionModule } from "@angular/material/expansion";
import { MatDividerModule } from "@angular/material/divider";
import { PreLandingComponent } from "./pages/pre-landing/pre-landing.component";
import { MatIconModule } from "@angular/material/icon";
import { EditBookingComponent } from "./pages/edit-booking/edit-booking.component";
import { DeleteBookingComponent } from "./pages/delete-booking/delete-booking.component";
import { DeleteCompletedComponent } from "./pages/delete-completed/delete-completed.component";
import { HomeLandingBookedComponent } from "./pages/home/home-landing-gestore/home-landing-booked.component";
import { MatRadioModule } from "@angular/material/radio";
import { SummaryBookedComponent } from "./pages/summary/summary-gestore/summary-booked.component";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { CalendarConsultantComponent } from "./pages/calendar/calendar-gestore/calendar-consultant.component";
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { MatInputModule } from "@angular/material/input";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatDialogModule } from "@angular/material/dialog";
import { GenericConfirmationComponent } from "./components/dialog/generic-confirmation/generic-confirmation.component";

@NgModule({
  declarations: [
    AppComponent,
    HomeLandingComponent,
    HeaderComponent,
    FooterComponent,
    SummaryComponent,
    CompleteBookingComponent,
    CalendarComponent,
    PreLandingComponent,
    EditBookingComponent,
    DeleteBookingComponent,
    DeleteCompletedComponent,
    HomeLandingBookedComponent,
    SummaryBookedComponent,
    CalendarConsultantComponent,
    GenericConfirmationComponent,
  ],
  imports: [
    BrowserModule,
    MatDialogModule,
    AppRoutingModule,
    FormsModule,
    HttpClientModule,
    ReactiveFormsModule,
    MatProgressBarModule,
    MatSelectModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatCheckboxModule,
    MatExpansionModule,
    MatDividerModule,
    MatIconModule,
    MatRadioModule,
    MatProgressSpinnerModule,
    MatAutocompleteModule,
    MatFormFieldModule,
    MatInputModule,
    RecaptchaModule,
    RecaptchaFormsModule,
    BrowserAnimationsModule,
  ],
  providers: [{ provide: MAT_DATE_LOCALE, useValue: "it" }],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {}
