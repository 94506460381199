import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { StorageService } from './storage.service';

@Injectable({
  providedIn: 'root',
})
export class BookingService {
  private banksubject = new BehaviorSubject<any>('');
  private agencySubject = new BehaviorSubject<any>(null);
  private dateSubject = new BehaviorSubject<any>('');
  private editSubject = new BehaviorSubject<string | null>(null);

  constructor(private http: HttpClient, private storage: StorageService) {}

  sendBankObject(object: any) {
    this.banksubject.next(object);
    this.storage.addElement(object, 'bank');
  }

  getBankObject(): any {
    let tmp = this.storage.getElement('bank');
    if(tmp) {
      this.banksubject.next(tmp);
    }

    return this.banksubject.getValue();
  }

  sendEditId(object: string) {
    this.editSubject.next(object);
    this.storage.addElement(object, 'edit');
  }

  getEditId(): string | null {
    let tmp = this.storage.getElement('edit');
    if(tmp) {
      this.editSubject.next(tmp);
    }

    return this.editSubject.getValue();
  }

  sendAgencyId(object: [number, number]) {
    this.agencySubject.next(object);
    this.storage.addElement(object, 'agency');
  }

  getAgencyId(): any[] | null {
    let tmp = this.storage.getElement('agency');
    if(tmp) {
      this.agencySubject.next(tmp);
    }

    return this.agencySubject.getValue();
  }

  sendDateandTimeObject(object: any) {
    this.dateSubject.next(object);
    this.storage.addElement(object, 'date');
  }

  getDateandTimeObject(): any {
    let tmp = this.storage.getElement('date');
    if(tmp) {
      this.dateSubject.next(tmp);
    }

    return this.dateSubject.getValue();
  }

  getCities(): Observable<any> {
    let url = environment.backEndUrl + '/available-cities';
    return this.http.get(url);
  }

  getAgencies(id_city: number): Observable<any> {
    let url = environment.backEndUrl + `/${id_city}/agencies`;
    return this.http.get(url);
  }

  getTimeAgency(id_agency: number): Observable<any> {
    let url = environment.backEndUrl + `/agencies/${id_agency}/hours`;
    return this.http.get(url);
  }

  getOrari(body: any, idAgency: number): Observable<any> {
    let url = environment.backEndUrl + `/agencies/${idAgency}/slots`;
    return this.http.post(url, body);
  }

  getAgencyAvailableDays(idAgency: number): Observable<any> {
    let url = environment.backEndUrl + `/agencies/${idAgency}/availability`;

    return this.http.get(url);
  }

  createReservation(obj: any, uuid: string | undefined): Observable<any> {
    let url = environment.backEndUrl + '/reservations/agency';

    if (uuid) {
      let params = new HttpParams();
      params = params.append('id', uuid);

      return this.http.post(url, obj, {params: params});
    }
    return this.http.post(url, obj);
  }

  getReservationbyId(id: String): Observable<any> {
    let url = environment.backEndUrl + `/reservations/agency/${id}`;
    return this.http.get(url);
  }

  getConsultantReservationbyId(id: String): Observable<any> {
    let url = environment.backEndUrl + `/reservations/consultant/${id}`;
    return this.http.get(url);
  }

  deleteReservation(id: string): Observable<any> {
    let url = environment.backEndUrl + `/reservations/agency/${id}`;
    return this.http.delete(url);
  }

  editReservation(obj: any, id: string, uuid: string | undefined): Observable<any> {
  let url = environment.backEndUrl + `/reservations/agency/${id}`;
  if (uuid) {

    let params = new HttpParams();
    params = params.append('id', id);
    return this.http.post(url, obj, {params: params});
  }
  return this.http.post(url, obj);
  }

  getAgencyIdByCredemCode(credem_id: number) {
    let url = environment.backEndUrl + `/agencies`;

    let params = new HttpParams();
    params = params.append('id', credem_id.toString());

    return this.http.get(url, {params: params});
  }

  getCashServices(id_agency: number): Observable<any> {
    let url = environment.backEndUrl + `/agencies/${id_agency}/services`;
    return this.http.get(url);
  }
}
