import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BookingService } from 'src/app/service/booking.service';
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
} from '@angular/material/core';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import * as moment from 'moment';
import { finalize } from 'rxjs/operators';
import { formatDate } from '@angular/common';

export const MY_FORMATS = {
  parse: {
    dateInput: 'LL',
  },
  display: {
    dateInput: 'YYYY-MM-DD',
    monthYearLabel: 'YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'YYYY',
  },
};

@Component({
  selector: 'app-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.scss'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE],
    },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
})
export class CalendarComponent implements OnInit {

  isLoading: boolean = true;
  canGoBack: boolean = true;
  private selectedDate: string;
  selectionDate: Date = new Date();
  isComplete: boolean = false;
  orari_mattino: any[] = [];
  orari_pomeriggio: any[] = [];
  orari_mattino_disabled: boolean = false;
  orari_pomeriggio_disabled: boolean = false;
  private selectedTime: boolean = false;
  private timeOfDay: string;
  minDate = new Date();
  maxDate = new Date();

  private id_agency: number;
  private credem_id_agency: number;

  private availableDays: any[] = []

  isMattinoExpanded: boolean = false;
  isPomeriggioExpanded: boolean = false;

  constructor(private route: Router, private bookingService: BookingService) {}

  ngOnInit() {
    this.getAgencyObj();
    this.checkBookedReservation();
  }

  private getAgencyObj() {
    let res = this.bookingService.getAgencyId();
    if (res) {
      this.id_agency = res[0];
      this.credem_id_agency = res[1];
      this.getAgencyAvailableDays();
    }
  }

  private getAgencyAvailableDays() {
    this.bookingService.getAgencyAvailableDays(this.id_agency).subscribe(
      res => {
        this.availableDays = res
        
        let tmpMin: Date | null = null;
        let tmpMax: Date | null = null;

        this.availableDays.forEach(el => {
          if (!tmpMin && el.available) tmpMin = new Date(el.year, el.month-1, el.day);
          if (el.available) tmpMax = new Date(el.year, el.month-1, el.day);
        });

        if (tmpMin && tmpMax) {
          this.minDate = tmpMin;
          this.maxDate = tmpMax;

          this.getMinDateSelectable();
        }
      },
      error => {
        console.error(error)
      }
    );
  }

  private getMinDateSelectable() {

    let toAdd = 0;

    for(let el of this.availableDays) {
      if(el.available) break
      toAdd+=1
    }

    var obj = moment().add(toAdd, "days");
    this.handleSelection(obj);
  }

  dateFilter = (d: any): boolean => {
    var calendar_date = d._i;

    calendar_date = this.availableDays.find(el => {
      return el.year == calendar_date.year && el.month == (calendar_date.month+1) && el.day == calendar_date.date
    });

    if (calendar_date == undefined) return false;
    
    return calendar_date.available
  };

  private checkBookedReservation() {
    let res = this.bookingService.getEditId();
    if (res) {
      this.canGoBack = false;
    }
  }

  goBack() {
    let baseUrl = '/home';
    if (this.credem_id_agency) {
      baseUrl += `/${this.credem_id_agency}`;
    }
    
    this.route.navigate([baseUrl]);
  }

  handleBooking(obj: any) {
    this.orari_mattino.forEach((el) => {
      if (el.selected) {
        el.selected = false;
      }
    });
    this.orari_pomeriggio.forEach((el) => {
      if (el.selected) {
        el.selected = false;
      }
    });

    obj.selected = !obj.selected;
    this.selectedTime = obj.ora;
    this.timeOfDay = obj.time;
    this.isComplete = true;
  }

  isDisabled(orari_morning: any, orari_afternoon: any) {
    if (orari_morning.length == 0) {
      this.orari_mattino_disabled = true;
    } else {
      this.isMattinoExpanded = true;
      this.orari_mattino_disabled = false;
    }

    if (orari_afternoon.length == 0) {
      this.orari_pomeriggio_disabled = true;
    } else {
      this.orari_pomeriggio_disabled = false;
    }
  }

  handleSelection(event: any) {
    this.isLoading = true;

    this.isMattinoExpanded = false;
    this.isPomeriggioExpanded = false;
    this.orari_mattino_disabled = true;
    this.orari_pomeriggio_disabled = true;
    this.orari_mattino = [];
    this.orari_pomeriggio = [];
    
    this.selectionDate = event;
    this.selectedDate = formatDate(event._d, 'yyyy-MM-dd', 'en-US');

    let body = {
      day: this.selectedDate,
    };

    this.bookingService
      .getOrari(body, this.id_agency)
      .pipe(
        finalize(() =>
          this.isDisabled(this.orari_mattino, this.orari_pomeriggio)
        )
      )
      .subscribe((res) => {
        if (res) {
          res.forEach((el: any) => {
            if (el.time_day == 'M') {
              this.orari_mattino.push({
                ora: el.hour,
                time: el.time_day,
                stato: el.available,
                selected: false,
              });
            } else {
              this.orari_pomeriggio.push({
                ora: el.hour,
                time: el.time_day,
                stato: el.available,
                selected: false,
              });
            }
          });
        }

        this.isLoading = false;
      });
  }

  goToSummary() {
    let result = {
      ora: this.selectedTime,
      data: this.selectedDate,
      timeDay: this.timeOfDay,
    };
    this.bookingService.sendDateandTimeObject(result);
    this.route.navigate(['summary']);
  }
}
