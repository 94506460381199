import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
}

addAnalytics()

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));

function addAnalytics() {
  if (environment.analytics) {
    let head = document.getElementsByTagName('head')[0];

    let node = document.createElement('script');
    node.src = 'https://www.googletagmanager.com/gtag/js?id=' + environment.analytics;
    node.type = 'text/javascript';
    node.async = true;

    let node2 = document.createElement('script');
    node2.innerText = `window.dataLayer = window.dataLayer || [];function gtag(){dataLayer.push(arguments);}gtag('js', new Date());gtag('config', '${environment.analytics}');`;

    head.appendChild(node);
    head.appendChild(node2);
  }
}