import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { GenericConfirmationComponent } from 'src/app/components/dialog/generic-confirmation/generic-confirmation.component';
import { BookingService } from 'src/app/service/booking.service';
import { BookingConsultantService } from 'src/app/service/booking-consultant.service';

import { HomeService } from 'src/app/service/home.service';
import { StorageService } from 'src/app/service/storage.service';

@Component({
  selector: 'app-edit-booking',
  templateUrl: './edit-booking.component.html',
  styleUrls: ['./edit-booking.component.scss']
})
export class EditBookingComponent implements OnInit {

  private idReservation: string;
  type: string;
  isLoading: boolean = true;

  constructor(
     private route: Router,
     private router: ActivatedRoute,
     private bookingService: BookingService,
     private bookingConsultantService: BookingConsultantService,
     private homeService: HomeService,
     private storage: StorageService,
     private dialog: MatDialog
  ) { }

  ngOnInit() {
    this.storage.clearStorage();

    this.router.params.subscribe(params => {
      if (params && params.id) {
        this.idReservation = params.id;

        this.router.queryParams.subscribe(queryParams => {
          if (queryParams && queryParams.type) {
            this.type = queryParams.type;
          } else {
            this.type = 'cassa';
          }

          let reservationService = undefined;
          let flow = ""

          if (this.type == 'gestore') {
            flow = "GESTORE";
            reservationService = this.bookingConsultantService.getReservationConsultant(this.idReservation);
          } else {
            flow = "CASSA";
            reservationService = this.bookingService.getReservationbyId(this.idReservation);
          }

          reservationService.subscribe(
            res => {
              if (res) {
                let userData = {
                  "jwt": res,
                  "flow": flow
                }
                this.bookingService.sendEditId(this.idReservation);
                this.homeService.setUserData(userData, this.idReservation);

                if (this.type == 'gestore'){
                  //
                } else{
                  this.bookingService.sendAgencyId([res.id_agency, res.code]);
                }

                this.isLoading = false;
              } else {
                this.showError("");
              }
            },
            err => {
              this.showError(err);
            }
          );
        });
      } else {
        this.showError("");
      }
    });
  }

  goTo(path: string) {
    switch(path) {
      case 'edit':
        if (this.type == 'gestore'){
           this.route.navigate(["/home-booked-consultant"]);
        }
        else{
           this.route.navigate(["/date-picking"]);
        }
        break;
      case 'cancel':
        this.route.navigate(["/cancel"], {queryParams : {id: this.idReservation, type: this.type}});
        break;
      case 'landing':
        this.route.navigate(["/"]);
    }
  }

  private showError(err: any) {
    let message = "Si è verificato un errore. Riprova.";
    if (err.error.type == "handled") {
      message = err.error.message;
    }
    
    const dialogRef = this.dialog.open(GenericConfirmationComponent, {
      data: {
        message: message
      }
    });

    dialogRef.afterClosed().subscribe((result) => {
      this.goTo('landing');
    });
  }

}
