import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { BookingService } from "src/app/service/booking.service";
import { finalize } from "rxjs/operators";
import { HomeService } from "src/app/service/home.service";
import { GenericConfirmationComponent } from "src/app/components/dialog/generic-confirmation/generic-confirmation.component";
import { MatDialog } from "@angular/material/dialog";

@Component({
  selector: "app-summary",
  templateUrl: "./summary.component.html",
  styleUrls: ["./summary.component.scss"],
})
export class SummaryComponent implements OnInit {
  isLoading: boolean = true;
  private id_agency: number;
  filiale: string;
  address: string;
  time: string;
  data: string;
  private time_of_day: string;
  private idReservation: string;
  private new_idReservation: string;
  private token: string;
  private tokenSolved: boolean = false;
  clienteIntestazione: boolean = false;

  blacklist: Array<string>;

  jwt: any;
  private uuid: string;

  form: FormGroup = this.fb.group({
    name: ["", [forbiddenNameValidator]],
    surname: ["", [forbiddenNameValidator]],
    email: ["", [Validators.email, Validators.required]],
    email_2: ["", [Validators.email, Validators.required]],
    terminiChecked: [false],
    intestazione: [""]
  });

  constructor(
    private route: Router,
    private bookingService: BookingService,
    private fb: FormBuilder,
    private homeService: HomeService,
    private dialog: MatDialog
  ) {}

  ngOnInit() {
    let res = this.bookingService.getAgencyId();
    if(res) {
      this.id_agency = res[0];
    }

    let res2 = this.bookingService.getDateandTimeObject();
    if(res2) {
      this.time = res2.ora;
      this.data = JSON.parse(JSON.stringify(res2.data));
      this.time_of_day = res2.timeDay;
    }

    let res3 = this.bookingService.getBankObject();
    if (res3) {
      this.filiale = res3.filiale;
      this.address = res3.indirizzo;
    }

    this.getJwt();
    this.retrieveBookedReservation();

    if (!this.jwt) {
      this.homeService.getBlacklist().subscribe(
        res => {
          this.blacklist = res;
          this.isLoading = false;
        }
      );
    } else {
      this.isLoading = false;
    }
  }

  private getJwt() {
    let tmp = this.homeService.getUserData();
    if (tmp[0] && tmp[1]) {

      this.jwt = tmp[0].jwt;
      this.uuid = tmp[1];

      if(this.jwt.clienteIntestazione !== undefined){
        this.clienteIntestazione = true;
        this.form.get("intestazione")?.setValue(this.jwt.clienteIntestazione);
      } else {
        this.form.get("name")?.setValue(this.jwt.clienteNome);
        this.form.get("surname")?.setValue(this.jwt.clienteCognome);
      }

      this.form.get("email")?.setValue(this.jwt.clienteEmail);

      this.form.disable();

      this.form.get("terminiChecked")?.enable();
    }

  }

  private retrieveBookedReservation() {
    let res = this.bookingService.getEditId();
    if (res) {
      this.idReservation = res;

      this.filiale = this.jwt.agency_name;
      this.address = this.jwt.address;
      this.id_agency = this.jwt.id_agency;
    }
  }

  resolved() {
    this.token = grecaptcha.getResponse();
    this.tokenSolved = true;
  }

  onError() {
    console.log(`reCAPTCHA error encountered; details:`);
  }

  goBack() {
    this.route.navigate(["date-picking"]);
  }

  onSubmit() {
    if (!this.jwt) {
      let valid = this.checkBlacklist();
      if (!valid) return;
    }

    let result = {
      nome: this.form.get("name")?.value,
      cognome: this.form.get("surname")?.value,
      mail: this.form.get("email")?.value,
      time: this.time,
      date: this.data,
      id_agency: this.id_agency,
      indirizzo: this.address,
      terms: this.form.get("terminiChecked")?.value,
      time_day: this.time_of_day,
      token: this.token,
      intestazione: undefined,
    }

    if (this.jwt) {
      result.nome = this.jwt.clienteNome;
      result.cognome = this.jwt.clienteCognome;
      result.mail = this.jwt.clienteEmail;
      if(this.jwt.clienteIntestazione !== undefined){
        result.intestazione = this.jwt.clienteIntestazione;
      }
    }

    this.isLoading = true;
    let reservationService = undefined;

    if (this.idReservation != "0" && this.idReservation != undefined) {
      reservationService = this.bookingService.editReservation(result, this.idReservation, this.uuid);
    }else {
      reservationService = this.bookingService.createReservation(result, this.uuid);
    }

    reservationService
      .pipe(finalize(() => this.goToComplete(this.new_idReservation)))
      .subscribe(
        res => {
          if (res) {
            this.new_idReservation = res;
          }
        },
        err => {
          if (err.error.type == "handled") { this.homeService.setErrorMessage(err.error.message); }
        }
      );
  }

  private goToComplete(idReservation: string) {
    this.isLoading = false;
    this.route.navigate(["complete", { idReservation }]);
  }

  // Return True if invalid
  checkEmailFields() {
    return (!this.form.get('email_2')?.hasError('email') && this.form.get("email")?.value != this.form.get("email_2")?.value);
  }

  checkFormInvalid(){
    if(!this.form.get("intestazione")?.value && !(this.form.get("name")?.value && this.form.get("surname")?.value)) {
      return true;
    }

    if (this.jwt) {
      return false;
    } else {
      if(this.checkEmailFields()) return true;

      return (!this.form.get("terminiChecked")?.value || !this.tokenSolved)
    }
  }

  private checkBlacklist(): boolean {
    let email: string = this.form.get("email")?.value;

    let match = this.blacklist.find(el => email.includes(el));

    if (!match) return true;

    const dialogRef = this.dialog.open(GenericConfirmationComponent, {
      data: {
        message: "Non è possibile fissare un appuntamento con l'indirizzo e-mail indicato. L'indirizzo e-mail non deve contenere '" + match + "'."
      }
    });

    this.form.get("email")?.setValue('');
    this.form.get("email_2")?.setValue('');

    return false;
  }

}

function forbiddenNameValidator(control: FormControl) {
  let nameRe: RegExp = /\p{Emoji}/u;

  const forbidden = nameRe.test(control.value);
  return forbidden ? {forbiddenName: {value: control.value}} : null;
}
