<div *ngIf="isLoading">
  <div class="intestazione-container">
    <img
      class="image-footer"
      src="../../../assets/img/Logo-Credem-Wellbanking_rgb_alta.jpg"
    />
  </div>
  <div class="spinner-loading">
    <div>
      <span class="spinner-double-dot-stick"></span>
    </div>
  </div>
</div>

<div *ngIf="!isLoading" class="home-landing">
  <div class="intestazione-container">
    <div class="back-btn-container" *ngIf="!isQRCoded">
      <button class="btn-summary d-none d-md-block" (click)="goBack()">
        <mat-icon>chevron_left</mat-icon>
      </button>
      <p class="back-written d-none d-md-block" (click)="goBack()">indietro</p>
      <button class="btn-mobile d-md-none" (click)="goBack()">
        <mat-icon>chevron_left</mat-icon>
      </button>
    </div>
    <img
      class="image-footer"
      src="../../../assets/img/Logo-Credem-Wellbanking_rgb_alta.jpg"
    />
  </div>
  <div class="progress-container">
    <mat-progress-bar mode="determinate" value="25"></mat-progress-bar>
  </div>
  <div class="title-container">
    <h2>Scegli la filiale</h2>
  </div>

  <div class="select-container row">
    <mat-form-field
      class="col-md-6 col-12"
      appearance="legacy"
      color="primary"
      *ngIf="cities"
    >
      <input
        type="text"
        placeholder="Città"
        matInput
        [formControl]="cityAutocompleteControl"
        [matAutocomplete]="auto"
      />
      <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayCityFn">
        <mat-option
          *ngFor="let item of filteredCitiesOptions"
          [value]="item.id_city"
        >
          {{ item.name }} ({{ item.province }})
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>
    <mat-form-field class="col-md-6 col-12" appearance="legacy">
      <mat-label>Filiale</mat-label>
      <mat-select
        [(ngModel)]="selectedAgencyId"
        (selectionChange)="handleAgencySelection()"
        [disabled]="agencies.length==0"
      >
        <mat-option
          *ngFor="let agenzia of agencies"
          [value]="agenzia.id_agency"
        >
          {{ agenzia.name }} - {{ agenzia.address }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <div class="information-container" *ngIf="isComplete">
    <div class="information">
      <p style="font-weight: bold">{{ filiale }}</p>
      <p>{{ filiale_address }}</p>
      <p>{{ cityChosen }} ({{ provincia }})</p>
      <br />
      <p style="font-weight: bold">Orari Lun-Ven:</p>
      <p>
        {{ opening_hours }}
      </p>
      <br />
      <p style="font-weight: bold">Servizi di cassa Self:</p>
      <p *ngFor="let item of agency_services">
        {{ item.name }} {{ item.value }}
      </p>
    </div>
  </div>
  <div class="submit-btn">
    <button
      [ngClass]="isComplete ? 'btn-submit' : 'btn-disabled'"
      (click)="goToCalendar()"
      [disabled]="!isComplete"
      style="margin-bottom: 20px"
    >
      Procedi
    </button>
  </div>
</div>
