import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BookingConsultantService } from 'src/app/service/booking-consultant.service';
import { BookingService } from 'src/app/service/booking.service';
import { HomeService } from 'src/app/service/home.service';
import { StorageService } from 'src/app/service/storage.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-complete-booking',
  templateUrl: './complete-booking.component.html',
  styleUrls: ['./complete-booking.component.scss'],
})
export class CompleteBookingComponent implements OnInit {

  isConsultant: boolean = false;
  isCallCenter: boolean = false;

  successTitle: string;
  errorMessage: string;

  filiale: string;
  address: string;
  nome: string;
  surname: string;
  email: string;
  orario: string;
  idReservation: string;
  data: string;
  phone: string;
  argument: string;
  consultantName: string;

  isSuccess: boolean = false;
  isLoading: boolean = true;

  constructor(
    private route: Router,
    private router: ActivatedRoute,
    private bookingService: BookingService,
    private bookingConsultantService: BookingConsultantService,
    private homeService: HomeService,
    private storage: StorageService
  ) {}

  ngOnInit() {

    let tmp = this.homeService.getUserData();
    if(tmp[0]){
      let canale = tmp[0].jwt.canaleUtilizzo;
      if(canale == "VIC") this.isCallCenter = true;

      if(tmp[0].flow == "GESTORE") this.isConsultant = true;
    }

    this.successTitle = 'Attenzione! È stato riscontrato un errore';
    this.errorMessage = 'Qualcosa non ha funzionato correttamente in fase di prenotazione, ti invitiamo a provare nuovamente facendo clic sul pulsante "Ritenta".';

    this.router.params.subscribe((params) => {
      if (params) {
        if (
          params.idReservation != '0' &&
          params.idReservation != 'undefined'
        ) {
          this.idReservation = params.idReservation;
          this.successTitle = 'Consulta la tua e-mail per i dettagli della prenotazione';
          this.isSuccess = true;
          this.getReservation();
        } else {
          let message = this.homeService.getErrorMessage();
          if (message) this.errorMessage = message;

          this.showPage();
        }
      } else {
        this.showPage();
      }
    });
  }

  private getReservation() {
    if (this.isConsultant) {
      this.bookingConsultantService
        .getReservationConsultant(this.idReservation)
        .subscribe((res) => {
          if (res) {
            this.consultantName = res.gestoreNome + " " + res.gestoreCognome;
            this.orario = res.start;
            this.nome = res.clienteNome;
            this.surname = res.clienteCognome;
            this.data = res.date;
            this.email = res.clienteEmail;
            this.phone = res.clienteCellulare;
            this.argument = res.dropdown_argument;
          }

          this.showPage();
        }
      );
    } else {
      this.bookingService
        .getReservationbyId(this.idReservation)
        .subscribe((res) => {
          if (res) {
            this.address = res.address;
            this.filiale = res.agency_name;
            this.orario = res.start;
            this.nome = res.clienteNome;
            this.surname = res.clienteCognome;
            this.data = res.date;
            this.email = res.clienteEmail;
          }

          this.showPage();
        }
      );
    }
  }
  private showPage() {
    this.storage.clearStorage();
    this.isLoading = false;
  }

  get successImage() {
    if (this.isSuccess) {
      return '../../../assets/img/check.png';
    } else {
      return '../../../assets/img/remove.png';
    }
  }

  goToCalendar(isConsultant: boolean) {
    if (isConsultant) {
      this.route.navigate(['/date-picking-consultant']);
    } else {
      this.route.navigate(['/date-picking']);
    }
  }

  goToHome() {
    this.route.navigate(['/home']);
  }

  goToHomeCallCenter() {
    window.location.href = environment.callCenterUrl;
  }
}
