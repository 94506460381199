<div *ngIf="isLoading">
    <div class="intestazione-container">
        <img class="image-footer" src="../../../assets/img/Logo-Credem-Wellbanking_rgb_alta.jpg">
    </div>
    <div class="spinner-loading">
        <div>
            <span class="spinner-double-dot-stick"></span>
        </div>
    </div>
</div>

<div *ngIf="!isLoading" class="delete-booking">
  <div class="intestazione-container">
    <div class="back-btn-container">
      <button class="btn-summary d-none d-md-block" (click)="goBack(idReservation)" ><mat-icon>chevron_left</mat-icon></button><p class="back-written d-none d-md-block" (click)="goBack(idReservation)">indietro</p>
      <button class="btn-mobile d-md-none" (click)="goBack(idReservation)">
        <mat-icon>chevron_left</mat-icon>
      </button>
    </div>
    <img class="image-footer" src="../../../assets/img/Logo-Credem-Wellbanking_rgb_alta.jpg">
  </div>
  <div class="d-flex justify-content-center pt-4">
    <h3 style="font-weight: bold;color: green;">Cancella il tuo appuntamento</h3>
  </div>
  <div class="sub-title-container">
    <p>Ci dispiace che tu debba cancellare il tuo appuntamento. Per offrire un servizio migliore ti chiediamo di indicarci
      la motivazione della cancellazione.</p>
  </div>
  <div class="input-text-container">
    <label for="area">Motivo</label>
    <textarea [(ngModel)]="textValue" placeholder="Inserisci la motivazione della cancellazione dell'appuntamento."></textarea>
  </div>
  <div class="low-btn-container">
    <button class="btn-submit" (click)="deleteBooking()">Cancella appuntamento</button>
    <button class="btn-back-submit" (click)="goBack(idReservation)">Indietro</button>
  </div>
</div>
