import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HomeService } from 'src/app/service/home.service';
import { StorageService } from 'src/app/service/storage.service';

@Component({
  selector: 'app-pre-landing',
  templateUrl: './pre-landing.component.html',
  styleUrls: ['./pre-landing.component.scss']
})
export class PreLandingComponent implements OnInit {
  loading = true;

  constructor(
    private route: Router,
    private router: ActivatedRoute,
    private homeService : HomeService,
    private storage: StorageService
  ) { }

  ngOnInit() {
    this.storage.clearStorage();
    
    this.router.queryParams.subscribe(
      params => {
        if (params && params.id) {
          let uuid = params.id;
          this.homeService.getJWT(uuid).subscribe(
            res => {
              if(res){
                this.homeService.setUserData(res, uuid);
                if(res.flow == 'CASSA'){
                  this.goTo("home");
                } else{
                  this.goTo("consultant");
                }
              } 
              this.loading=false;
            },
            error=>{
              console.log(error);
              this.loading=false;
            }
          );
        } else {
          this.loading=false;
        }
      }
    ); 
  }

  goTo(path?: string) {
    let url: string;

    switch(path) {
      case "home":
        url = "/home"
        break;
      
      case "consultant":
        url = "/home-booked-consultant";
        break;

      default:
        url = "/home";
    }

    this.route.navigate([url]);
  }

}



