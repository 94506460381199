import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CalendarConsultantComponent } from './pages/calendar/calendar-gestore/calendar-consultant.component';
import { CalendarComponent } from './pages/calendar/calendar-cassa/calendar.component';
import { CompleteBookingComponent } from './pages/complete-booking/complete-booking.component';
import { DeleteBookingComponent } from './pages/delete-booking/delete-booking.component';
import { DeleteCompletedComponent } from './pages/delete-completed/delete-completed.component';
import { EditBookingComponent } from './pages/edit-booking/edit-booking.component';
import { HomeLandingBookedComponent } from './pages/home/home-landing-gestore/home-landing-booked.component';
import { HomeLandingComponent } from './pages/home/home-landing-cassa/home-landing.component';
import { PreLandingComponent } from './pages/pre-landing/pre-landing.component';
import { SummaryBookedComponent } from './pages/summary/summary-gestore/summary-booked.component';
import { SummaryComponent } from './pages/summary/summary-cassa/summary.component';


const routes: Routes = [
  {
    path: '',
    component: PreLandingComponent
  },
  {
    path: 'home',
    component: HomeLandingComponent
  },
  {
    path: 'home/:id',
    component: HomeLandingComponent
  },
  {
    path: 'date-picking',
    component: CalendarComponent
  },
  {
    path: 'summary',
    component: SummaryComponent
  },
  {
    path: 'complete',
    component: CompleteBookingComponent
  },
  {
    path: 'edit/:id',
    component: EditBookingComponent
  },
  {
    path: 'cancel',
    component: DeleteBookingComponent
  },
  {
    path: 'cancel-complete',
    component: DeleteCompletedComponent
  },
  {
    path: 'home-booked-consultant',
    component: HomeLandingBookedComponent
  },
  {
    path: 'home-booked-consultant/:id',
    redirectTo: ''
  },
  {
    path: 'date-picking-consultant',
    component: CalendarConsultantComponent
  },
  {
    path: 'summary-consultant',
    component: SummaryBookedComponent
  },
  { path: '**', redirectTo: '' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
