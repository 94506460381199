<div *ngIf="isLoading">
    <div class="intestazione-container">
        <img class="image-footer" src="../../../assets/img/Logo-Credem-Wellbanking_rgb_alta.jpg">
    </div>
    <div class="spinner-loading">
        <div>
            <span class="spinner-double-dot-stick"></span>
        </div>
    </div>
</div>

<div *ngIf="!isLoading" class="edit-booking">
    <div class="intestazione-container">
        <img class="image-footer" src="../../../assets/img/Logo-Credem-Wellbanking_rgb_alta.jpg">
      </div>
      <div class="d-flex justify-content-center m-5">
          <h1 style="color: green;font-weight: 500;"> Modifica Appuntamento </h1>
      </div>
      <div class="sub-title-container">
          <p *ngIf="type == 'cassa'">Vuoi modificare la data e
          l’ora dell’appuntamento, ma mantenerlo nella stessa filiale da te scelta?</p>
          <p *ngIf="type == 'gestore'">Vuoi modificare la data e
            l’ora dell’appuntamento con il tuo consulente?</p>
      </div>
      <div class="edit-btn-container">
          <button class="btn-submit" (click)="goTo('edit')">Modifica Appuntamento</button>
      </div>
      <div class="sub-title-container">
        <p *ngIf="type == 'cassa'">Vuoi cancellare l’appuntamento preso in filiale?</p>
        <p *ngIf="type == 'gestore'">Vuoi cancellare l’appuntamento preso con il tuo consulente?</p>
    </div>
    <div class="edit-btn-container">
        <button class="btn-submit" (click)="goTo('cancel')">Cancella appuntamento</button>
    </div>
</div>