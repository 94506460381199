import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class StorageService {

  constructor() { }

  addElement(data: any, key: string): void {
    localStorage.setItem(key, JSON.stringify(data));
  }

  getElement(key: string): any {
    let tmp = localStorage.getItem(key);
    if(tmp) {
      tmp = JSON.parse(tmp);
    }

    return tmp;
  }

  clearStorage() {
    localStorage.clear();
  }
}
