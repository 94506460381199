import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BookingService } from 'src/app/service/booking.service';
import { finalize } from 'rxjs/operators';
import {
  AbstractControl,
  FormControl,
  ValidatorFn,
  Validators,
} from '@angular/forms';

import { HomeService } from 'src/app/service/home.service';

//declare let gtag: Function; // Defined in index.html

@Component({
  selector: 'app-home-landing',
  templateUrl: './home-landing.component.html',
  styleUrls: ['./home-landing.component.scss'],
})
export class HomeLandingComponent implements OnInit {

  isLoading: boolean = true;
  isQRCoded: boolean = false;
  isComplete: boolean = false;

  selectedAgencyId: number | undefined;
  private internalAgencyId: number| undefined; //Da JWT, ID interno del nostro DB
  private credemAgencyId: number | undefined; //Da QR Code, ID dell'anagrafica Credem

  cities: any[] = [];
  agencies: any[] = [];
  
  filiale: string;
  cityChosen: string;
  provincia: string;

  opening_hours: string;

  filiale_address: string;
  agency_services: any[] = [];
  cityAutocompleteControl = new FormControl('', {
    validators: [this.autocompleteObjectValidator(), Validators.required],
  });

  filteredCitiesOptions: any[] = [];

  constructor(
    private route: Router,
    private router: ActivatedRoute,
    private bookingService: BookingService,
    private homeService: HomeService,
  ) {
    this.cityAutocompleteControl.valueChanges.subscribe((value) => {
      this.isComplete = false;
      this.agencies = [];

      if (typeof value !== 'string') {
        this.getSelectedAgencyId();
        this.handleCitySelection(value);
        this.filteredCitiesOptions = this.cities;
      } else {
        this.selectedAgencyId = undefined;

        this.filteredCitiesOptions = this.cities.filter((city) => {
          return city.name.toLowerCase().startsWith(value.toLowerCase());
        });
      }
    });
  }

  ngOnInit() {
    this.bookingService.getCities().subscribe((res) => {
      if (res) {
        this.cities = res;
        this.filteredCitiesOptions = this.cities;

        this.router.params.subscribe((params) => {

          if (params.id && !isNaN(params.id)) {
            this.isQRCoded = true;
            this.credemAgencyId = params.id;
            this.bookingService.getAgencyIdByCredemCode(params.id).subscribe(
              (resp: any) => {
                if (resp) {
                  this.internalAgencyId = parseInt(resp.id_agency);
                  this.cityAutocompleteControl.setValue(resp.id_city);
                }
              },
              err => {
                console.error(err);
                this.isLoading = false;
              }
            );
            
          } else {
            let tmp = this.homeService.getUserData();
            if (tmp[0]) {
              let jwt = tmp[0].jwt;

              if (jwt.filialeIdentificativo) {
                this.internalAgencyId = jwt.filialeIdentificativo;

                let city_id = this.cities.find(el => {
                  return el.name.toLowerCase().startsWith(jwt.filialeCitta.toLowerCase());
                }).id_city;

                this.cityAutocompleteControl.setValue(city_id);
              } else {
                this.isLoading = false;
              }
            } else {
              this.isLoading = false;
            }
          }
        });
      }
    });
  }

  private getSelectedAgencyId() {
    if(this.internalAgencyId) {
      this.selectedAgencyId = this.internalAgencyId;
      this.internalAgencyId = undefined;
    } else {
      this.selectedAgencyId = undefined;
    }
  }

  private handleCitySelection(city_id: number) {
    this.cities.forEach((el) => {
      if (el.id_city == city_id) {
        this.cityChosen = el.name;
        this.provincia = el.province;
      }
    });

    this.bookingService.getAgencies(city_id).subscribe((res) => {
      if (res) {
        this.agencies = res;

        if(this.selectedAgencyId) {
          this.handleAgencySelection();
        } else {
          if (this.agencies.length == 1) {
            this.selectedAgencyId = this.agencies[0].id_agency;
            this.handleAgencySelection();
          }
        }
      }
    });
  }

  handleAgencySelection() {
    if(!this.selectedAgencyId) return;

    this.agencies.forEach((el) => {
      if (el.id_agency == this.selectedAgencyId) {
        this.filiale = el.name;
        this.filiale_address = el.address;
        this.credemAgencyId = el.CODE;
      }
    });

    this.opening_hours = "";

    this.bookingService
      .getTimeAgency(this.selectedAgencyId)
      .pipe(finalize(() => this.handleInfo()))
      .subscribe((res) => {
        if (res) {
          res.forEach((el: any) => {
            let pipe = this.opening_hours.length > 0 ? " | " : ""
            if (el.start_morning ) {
              this.opening_hours = el.start_morning + " - " + el.end_morning + pipe + this.opening_hours;
            } else {
              this.opening_hours = this.opening_hours + pipe + el.start_afternoon + " - " + el.end_afternoon;
            }
          });
        }
      }
    );

    this.bookingService.sendAgencyId([this.selectedAgencyId, this.credemAgencyId!]);
  }

  private handleInfo() {
    if(!this.selectedAgencyId) return;

    this.bookingService.getCashServices(this.selectedAgencyId).subscribe((res) => {
      this.agency_services = res;
      
      this.isLoading = false;
      this.isComplete = true;
    });
  }

  goBack() {
    this.route.navigate(['']);
  }

  goToCalendar() {
    let result = {
      filiale: this.filiale,
      indirizzo: this.filiale_address,
    };
    
    this.bookingService.sendBankObject(result);
    this.route.navigate(['/date-picking']);
  }

  displayCityFn = (cityId: any): string => {
    let city = this.cities.find(city => { return city.id_city == cityId });

    return city ? `${city.name} (${city.province})` : '';
  };

  private autocompleteObjectValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      if (typeof control.value === 'string') {
        return { invalidAutocompleteObject: { value: control.value } };
      }
      return null;
    };
  }
}

