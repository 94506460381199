import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-delete-completed',
  templateUrl: './delete-completed.component.html',
  styleUrls: ['./delete-completed.component.scss']
})
export class DeleteCompletedComponent implements OnInit {

  isSuccess: boolean = true;
  type: string;

  constructor(
    private route: Router,
    private router: ActivatedRoute,
  ) { }

  ngOnInit() {
    this.router.queryParams.subscribe((params) => {
      if (params) {
        this.type = params.type;
      } else {
        this.type = 'cassa';
      }
    });
  }

  get successImage(){
    if(this.isSuccess){
      return "../../../assets/img/check.png"
    } else {
      return "../../../assets/img/remove.png"
    }
  }

  restart(){
    this.route.navigate([""]);
  }

}
