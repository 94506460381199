import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BookingService } from 'src/app/service/booking.service';
import { BookingConsultantService } from 'src/app/service/booking-consultant.service';


@Component({
  selector: 'app-delete-booking',
  templateUrl: './delete-booking.component.html',
  styleUrls: ['./delete-booking.component.scss'],
})
export class DeleteBookingComponent implements OnInit {
  subscription: any;
  textValue: string;
  idReservation: string;
  private type: string;
  isLoading: boolean = false;

  constructor(
    private route: Router,
    private router: ActivatedRoute,
    private bookingService: BookingService,
    private bookingConsultantService: BookingConsultantService
  ) {}

  ngOnInit() {
    this.router.queryParams.subscribe((params) => {
      if (params) {
        this.idReservation = params.id;
        this.type = params.type;
      } else {
        this.type = 'cassa';
      }
    });
  }

  deleteBooking() {
    this.isLoading = true;

    let deleteService;

    if (this.type == "gestore") {
      deleteService = this.bookingConsultantService.deleteReservationConsultant(this.idReservation);
    } else {
      deleteService = this.bookingService.deleteReservation(this.idReservation);
    }

    deleteService.subscribe(
      res => {
        this.route.navigate(['/cancel-complete'], {queryParamsHandling: 'preserve'});
      },
      err => {
        // TODO gestione errore
        this.isLoading = false;
        console.log(err);
      }
    );
  }

  goBack(idReservation: string) {
    this.route.navigate(['/edit', idReservation], {queryParamsHandling: 'preserve'});
  }
}
