import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { StorageService } from './storage.service';

@Injectable({
  providedIn: 'root',
})
export class BookingConsultantService {
  private consultantReservationSubject = new BehaviorSubject<any>('');
  private editSubject = new BehaviorSubject<string | null>(null);


  constructor(private http: HttpClient, private storage: StorageService) {}


  getEditId(): string | null {
    let tmp = this.storage.getElement('edit');
    if(tmp) {
      this.editSubject.next(tmp);
    }

    return this.editSubject.getValue();
  }

  sendConsultantReservation(object: any) {
    this.consultantReservationSubject.next(object);
    this.storage.addElement(object, 'gestoreDate');
  }

  getConsultantReservation(): any {
    let tmp = this.storage.getElement('gestoreDate');
    if(tmp) {
      this.consultantReservationSubject.next(tmp);
    }

    return this.consultantReservationSubject.getValue();
  }

  getConsultantSingleDayAvailability(obj: any, uuidJWT: string, activity: string): Observable<any> {
    let url = environment.backEndUrl + `/consultants/${uuidJWT}/availability`;
    let params = new HttpParams();
    params = params.append('activity', activity);
    return this.http.post(url, obj, {params: params});
  }

  getConsultantsAvailableDays(uuidJWT: string, activity: string): Observable<any> {
    let url = environment.backEndUrl + `/consultants/${uuidJWT}/availability`;
    let params = new HttpParams();
    params = params.append('activity', activity);
    return this.http.get(url,  {params: params});
  }

  editReservationConsultant(obj: any, uuid: string | undefined): Observable<any> {
    let url = environment.backEndUrl + `/reservations/consultant/${uuid}`;
    return this.http.post(url, obj);
  }

  createReservationConsultant(obj: any, uuid: string | undefined): Observable<any> {
    let url = environment.backEndUrl + '/reservations/consultant';

    if (uuid) {
      let params = new HttpParams();
      params = params.append('id', uuid);

      return this.http.post(url, obj, {params: params});
    }
    return this.http.post(url, obj);
  }

  getReservationConsultant(idReservation: string): Observable<any> {
    let url =
      environment.backEndUrl + `/reservations/consultant/${idReservation}`;
    return this.http.get(url);
  }

   deleteReservationConsultant(id: string): Observable<any> {
    let url = environment.backEndUrl + `/reservations/consultant/${id}`;
    return this.http.delete(url);
  }

}
